<template>
    <div>
        <form v-on:submit.prevent="save">
        <div class="mb-3">
            <label for="duration" class="form-label">Anno schede:</label>
            <input type="text" class="form-control" v-model="anno" required>
        </div>
            <button id="btnSalva" type="submit" class="btn btn-primary mt-3">Salva</button>
        </form>
        <div v-if="message" class="alert alert-danger" role="alert">
            {{ message }}
        </div>
    </div>
</template>

<script>
import { computed,ref } from "vue";
import { useRouter} from 'vue-router';
import { useStore } from 'vuex';
import eServices from "../services/employees.service";

export default {
name:"settings",
    setup(){
    
        const store = useStore();
        const router = useRouter();
        const anno = ref("");
        const message = ref("");

        const loggedIn = computed(() => {return store.state.auth.status.loggedIn;});

        if(!loggedIn.value){
            router.push({ name: "home" });
        }

        const save = () => {


          
            eServices.globalUpdateYear(anno.value).then(
                () => {
                    
                },
                (error) => {
                message.value =
                    (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                    error.message ||
                    error.toString();
                }
            ); 

        }



        return{
            loggedIn,
            anno,
            save
        }
    }
}
</script>

<style scoped>
.btn-circle {
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 6px 0;
  font-size: 12px;
  line-height: 1.428571429;
  border-radius: 15px;
  margin-right: 15px;
  cursor: pointer;
}
.contacts-title{
    display: inline-flex;
}
.btn-add-contact{
    margin-top: -6px;
}
.contacts{
    border: 1px solid;
}
</style>